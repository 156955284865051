import React, { Component,useState,Fragment } from "react"
import { Helmet } from "react-helmet"
import gql from "graphql-tag"
import styled from "@emotion/styled"
import { Query, graphql,Mutation } from "react-apollo"
import { LoadingPage } from "../UI"
import { MaxWidth } from "../UI/Containers"
import { FormContainer,FormFlex } from "./Contact"
import { Input, TextArea } from "../UI/Input";
import { SubmitButton } from "../UI/Button"
import { SentMessage } from "./InterestBusiness"

const InterestWrapper = styled("section")`
  background: ${(props) => props.theme.colors.primary};
  color: #fff;
  padding: ${(props) => props.theme.spacing.default};
  text-align: center;
  .column {
    flex-direction: column;
    align-items: center;
  }
  input,
  textarea {
    margin: 1rem !important;
    position: relative;
    max-width: 400px;
    width: calc(100% - 2rem);
  }
`;


export const ApartmentContent = (props) => {
  const page = props.page;
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{page.title}</title>
      </Helmet>


      <React.Fragment>
        <PageSection>
          <MaxWidth
            dangerouslySetInnerHTML={{
              __html: page.content,
            }}
          />
        </PageSection>
        <ApartmentForm/>
        {page.boxSet &&
          page.boxSet.map((box, index) => {
            return (
              <PageSection key={index}>
                <MaxWidth>
                  <h1>{box.title}</h1>
                  {box.content && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: box.content,
                      }}
                    />
                  )}
                </MaxWidth>
              </PageSection>
            );
          })}
      </React.Fragment>
    </div>
  );
};




const ApartmentForm = (props) => {
  const [form, setForm] = useState({});
  const [sent, setSent] = useState(false);
const [sendData, setSendData] = useState({});
  const updateData = (e) => {
    let formData = form;
    formData[e.target.name] = e.target.value;
    setForm(formData);
    const newData = {
      name: formData.name,
      email: formData.email,
      message: `Adress: ${formData.address} \nTelefon: ${
        formData.phone
      } \nPersonnummer: ${formData.pid} \nArbetsplats: ${
        formData.work
      } \nMaxhyra: ${formData.rent} \nÖvrigt: ${formData.other}`,
      sendTo: "lagenheter@orvelinfastigheter.se",
      subject: `Intresseanmälan lägenhet från ${formData.name}`,
    };
    setSendData(newData);
  };

  return (
    <Fragment>
      <InterestWrapper>
        <h2>Intresseanmälan Lägenheter</h2>
        <MaxWidth>
          {sent ? (
            <SentMessage>
              <p>Din intresseanmälan mail är skickad!</p>
            </SentMessage>
          ) : (
            <form onChange={updateData}>
              <FormFlex className="column">
                <Input name="name" placeholder="Vad heter du?" required />
                <Input
                  name="email"
                  type="email"
                  placeholder="Vad är din e-post?"
                  required
                />
                <TextArea name="address" placeholder="Fullständig adress" />
                <Input name="phone" placeholder="Telefonnummer" required />
                <Input name="pid" placeholder="Personnummer" required />
                <Input
                  name="work"
                  placeholder="Nuvarand arbetsplats"
                  required
                />
                <Input
                  name="rent"
                  placeholder="Maxhyra"
                  required
                  type="number"
                />
                <TextArea
                  name="other"
                  placeholder="Övriga önskemål (ex. antal rum)"
                />
                <Input name="type" required type="hidden" value="LÄGENHET" />
              </FormFlex>
              <Mutation
                mutation={FORM_MUTATION}
                variables={{ emailData: sendData }}
              >
                {(postMutation, { loading, error, data }) => (
                  <Fragment>
                    {error && (
                      <p>Något gick fel, har du fyllt i samtliga fält?</p>
                    )}
                    <SubmitButton
                      onClick={(e) => {
                        e.preventDefault();
                        postMutation();
                      }}
                    >
                      {loading ? "Skickar..." : "Skicka intresseanmälan!"}

                      {data && setSent(data.sendEmail.ok)}
                    </SubmitButton>
                  </Fragment>
                )}
              </Mutation>
            </form>
          )}
        </MaxWidth>
      </InterestWrapper>
    </Fragment>
  );
};

const FORM_MUTATION = gql`
  mutation SendMail($emailData: EmailInput!) {
    sendEmail(emailData: $emailData) {
      ok
    }
  }
`;

class Interest extends Component {
    render() {
        const slug = this.props.match.params.slug
        return (
            <Query query={query} variables={{ slug }}>
                {({ loading, err, data }) => {
                    if (loading) return <LoadingPage />
                    return <ApartmentContent {...data} />
                }}
            </Query>
        )
    }
}
const query = gql`
    query Page($slug: String) {
        page(slug: $slug) {
            headTitle
            excerpt
            headLink
            headLinkText
            title
            content
            template
            iframeUrl
            image {
                url
            }
            boxSet {
                title
                content
                iframeUrl
            }
        }
    }
`

export const PageSection = styled("section")`
    line-height: 1.5em;
    &:nth-child(even) {
        background: ${props => props.theme.colors.accent};
    }
    padding: ${props => props.theme.spacing.default};
    ${props => props.theme.bp.below.sm} {
        padding: ${props => props.theme.spacing.small};
    }
    > div {
        padding: 0px 150px;
        ${props => props.theme.bp.below.sm} {
            padding: 0px;
    }
    a{
        text-decoration:underline;
    }
    table{
        width:100%;
        border-collapse:collapse;
        th,td{
            margin:0;
            border:0;
            padding:.5rem;
            text-align:left;
        }
        tr:nth-of-type(even){
            background:white;
        }
        tr:nth-of-type(odd){
            background:#f1f1f1;
        }
        th{
            background: ${props => props.theme.colors.secondary};
            color:white;
        }
    }

    table.invoice-list{
        ${props => props.theme.bp.below.sm} {
            display:block;
            thead{
                display:none;
            }
            tbody{
                display:block;
            }
            tr{
                background:white;
                margin-bottom:1.5rem;
                display:flex;
                flex-direction:column;
                font-size:13px;
                td:nth-of-type(1){
                    background: ${props => props.theme.colors.secondary};
                    color:white;
                    font-size:16px;
                    font-weight:700;
                }
                td:nth-of-type(2),
                td:nth-of-type(3),
                td:nth-of-type(4){
                    display:flex;
                    &::before{
                        font-weight:700;
                        width:30%;
                    }
                }
                td:nth-of-type(2)::before{
                        content:"Adress";
                    
                }
                td:nth-of-type(3)::before{
                        content:"Postnr + Ort";
                }
                td:nth-of-type(4)::before{
                        content:"Mail för PDF";
                }
            }
        }
    }
`

export default graphql(query)(Interest);
