import React, { Component } from "react";
import { Helmet } from "react-helmet";
import styled from "@emotion/styled";
import gql from "graphql-tag";
import { Query, graphql } from "react-apollo";
import { LoadingPage } from "../UI";
import BusinessList from "../Business/BusinessList";
import { NewsList } from "../News";
import PageHeader from "./PageHeader";
import { Below, Above } from "../UI/Helpers";
//import NewLocale from "../img/orvelin-new-banner.jpg"

const StartPageSection = styled("section")`
  background: ${(props) => props.theme.colors.accent};
  display: flex;
  flex-wrap: wrap;
  padding: 50px;
  ${(props) => props.theme.bp.below.sm} {
    padding: 2rem;
  }
  > div {
    width: 50%;
    padding: 20px;
    ${(props) => props.theme.bp.below.md} {
      width: 100%;
    }
  }
  blockquote {
    padding: 0px 100px;
    font-size: 3rem;
    &,
    p {
      color: ${(props) => props.theme.colors.primary};
      font-family: ${(props) => props.theme.fonts.headings};
      font-style: italic;
      text-align: center;
    }
    ${(props) => props.theme.bp.below.sm} {
      padding: 0;
      font-size: 2rem;
    }
  }
  p {
    line-height: 1.5em;
  }
`;

const PageContent = (props) => {
  const page = props.startpage;
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{page.title}</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      {page.image.url ? <PageHeader pageData={page} /> : null}

      <StartPageSection>
        <div>
          <h1>{page.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: page.content }} />
        </div>

        {page.boxSet.map((v, i) => {
          return (
            <div
              key={`box-${i}`}
              dangerouslySetInnerHTML={{ __html: v.content }}
            />
          );
        })}
        <NewsList first={3} />
      </StartPageSection>
      <Below size="xl">
        <BusinessList first={2} />
      </Below>
      <Above size="xl">
        <BusinessList first={3} />
      </Above>
    </div>
  );
};

class StartPage extends Component {
  render() {
    return (
      <Query query={query}>
        {({ loading, err, data }) => {
          if (loading) return <LoadingPage />;
          return <PageContent {...data} />;
        }}
      </Query>
    );
  }
}
const query = gql`
  {
    startpage {
      headTitle
      excerpt
      headLink
      headLinkText
      title
      content
      image {
        url
      }
      boxSet {
        content
      }
    }
  }
`;
export default graphql(query)(StartPage);
