import styled from "@emotion/styled"

const Input = styled("input")`
	border: 0;
	padding: 15px;
	font-size: 18px;
	display: block;
	width: 100%;
`
const TextArea = styled("textarea")`
	border: 0;
	padding: 15px;
	font-size: 18px;
	display: block;
	width: 100%;
	height: 100%;
	resize: none;
`
export { Input, TextArea }
