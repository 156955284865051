import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import gql from "graphql-tag";
import styled from "@emotion/styled";
import { Query, graphql } from "react-apollo";
import { LoadingPage } from "../UI";
import { BumperHeader } from "../UI/Placeholder";
import { MaxWidth } from "../UI/Containers";
import { Link } from "react-router-dom";
import DateDisplay from "../UI/Date";

const SearchResult = ({ post }) => {
  const slug = post.category ? "/fastighet/" : "/nyheter/";
  return (
    <SearchResultWrapper>
      <h3>
        <Link to={slug + post.slug}>{post.title}</Link>
      </h3>
      {post.category && post.category}
      {post.time && <DateDisplay timestamp={post.time.timestamp} />}
    </SearchResultWrapper>
  );
};

const SearchContent = (props) => {
  const results =
    props.propertySearch !== undefined
      ? props.propertySearch.edges
      : props.postSearch.edges;
  if (results.length > 0) {
    return (
      <Fragment>
        <div>
          {results.map((e, i) => {
            return <SearchResult key={i} post={e.node} />;
          })}
        </div>
      </Fragment>
    );
  }
  return null;
};

const SearchResultWrapper = styled("div")`
  margin-bottom: 1rem;
`;

const ResultList = styled(MaxWidth)`
  max-width: 800px;
  text-align: left;

  ${(props) => props.theme.bp.below.md} {
    width: 100vw;
    padding: 0 5vw;
  }
`;

const ResultWrapper = styled("div")`
  padding: 1rem 0;
  text-align: center;
  background: #f7f7f7;
`;

class SearchPage extends Component {
  state = { pResult: 0, nResult: 0 };

  updateResults = (num, type) => {
    console.log(num, type);
  };

  render() {
    const searchTerm = this.props.match.params.searchTerm;
    return (
      <Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Sökresultat för "{searchTerm}"</title>
        </Helmet>
        <BumperHeader />

        <ResultWrapper>
          <h1>Sökresultat för "{searchTerm}"</h1>
          <ResultList>
            <Query query={propertyQuery} variables={{ searchTerm }}>
              {({ loading, err, data }) => {
                if (loading) return <LoadingPage />;
                return (
                  <SearchContent updateResults={this.updateResults} {...data} />
                );
              }}
            </Query>
          </ResultList>
          <ResultList>
            <Query query={postQuery} variables={{ searchTerm }}>
              {({ loading, err, data }) => {
                if (loading) return <LoadingPage />;
                return (
                  <SearchContent updateResults={this.updateResults} {...data} />
                );
              }}
            </Query>
          </ResultList>
        </ResultWrapper>
      </Fragment>
    );
  }
}
const propertyQuery = gql`
  query PropertySearch($searchTerm: String) {
    propertySearch(searchTerm: $searchTerm) {
      edges {
        node {
          category
          title
          slug
          content
          website
          image(version: "business_box") {
            url
          }
        }
      }
    }
  }
`;

const postQuery = gql`
  query PostSearch($searchTerm: String) {
    postSearch(searchTerm: $searchTerm) {
      edges {
        node {
          title
          slug
          content
          image {
            url
          }
          time {
            timestamp
          }
        }
      }
    }
  }
`;

export default graphql(propertyQuery)(SearchPage);
