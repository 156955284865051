import React, { Component, useContext } from "react"
import styled from "@emotion/styled"
import gql from "graphql-tag"
import { Query } from "react-apollo"
import { ApolloContext } from "react-apollo"
import { MaxWidth, FlexWrap } from "../UI/Containers"
import { useQuery } from "@apollo/react-hooks"
import stromstad from "../img/stromstad.png"
import { Link } from "react-router-dom"

const FooterWrapper = styled("footer")`
    text-align: left;
    background: ${props => props.theme.colors.secondary};
    color: #fff;
    position: relative;
`

const FooterLogoWrapper = styled("div")`
    background: ${props => props.theme.colors.accent};
    padding: ${props => props.theme.spacing.default};
`

const ImgHolder = styled("a")`
    filter: grayscale(1);
    transition: 0.3s;
    img {
        max-width: 110px;
        min-width: ${props => (props.type === "svg" ? "90px" : "none")};
        height: auto;
        width: auto;
        margin: 0.75rem;
        max-height: 70px;
        mix-blend-mode: ${props =>
            props.type === "jpg" ? "multiply" : "none"};
        ${props => props.theme.bp.below.sm} {
            max-width: 80px;
            max-height: 40px;
            min-width: ${props => (props.type === "svg" ? "80px" : "none")};
        }
    }
    &:hover {
        filter: none;
    }
`

const LogoFlexWrap = styled(FlexWrap)`
    flex-wrap: wrap;
    justify-content: center;
`

const BusinessInfo = styled("div")`
    padding: ${props => props.theme.spacing.default};
    line-height: 1.5em;
    display: flex;
    justify-items: space-between;
    ${props => props.theme.bp.below.sm} {
        flex-wrap: wrap;
        padding-right: 120px;
    }
    h2 {
        margin-bottom: 1em;
        font-family: ${props => props.theme.fonts.body};
    }
    .footerMenu {
        padding-left: 2rem;
        ${props => props.theme.bp.below.sm} {
            padding-left: 0rem;
            padding-top: 2rem;
        }
    }
    ul {
        padding-left: 0;
        list-style: none;
        a {
            color: white;
        }
    }
`

const StromStadWrapper = styled("div")`
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    max-width: 100px;
    img {
        max-width: 100%;
        height: auto;
        min-width: 80px;
    }
    ${props => props.theme.bp.below.sm} {
        max-width: 80px;
    }
`
const FOOTER_PROPS = gql`
    query footerProps {
        allProperties(footer: true) {
            edges {
                node {
                    title
                    website
                    logo(version: "logo") {
                        url
                    }
                }
            }
        }
    }
`

const FooterLogos = props => {
    const apollo = useContext(ApolloContext)
    const { loading, error, data } = useQuery(FOOTER_PROPS, {
        client: apollo.client
    })
    if (loading) return null
    if (error) return null
    const isProd = process.env.NODE_ENV !== "development"
    return (
        <FooterLogoWrapper>
            <MaxWidth>
                <LogoFlexWrap>
                    {data.allProperties.edges.map(({ node }, index) => {
                        return (
                            <ImgHolder
                                key={index}
                                href={node.website}
                                target="_blank  ">
                                <img
                                    alt={node.title}
                                    src={
                                        isProd
                                            ? node.logo.url
                                            : `http://localhost:3020${node.logo.url}`
                                    }
                                />
                            </ImgHolder>
                        )
                    })}
                </LogoFlexWrap>
            </MaxWidth>
        </FooterLogoWrapper>
    )
}

const FooterMenu = () => {
    const apollo = useContext(ApolloContext)
    const { loading, error, data } = useQuery(menuQuery, {
        client: apollo.client
    })
    if (loading) return null
    if (error) return null
    return (
        <div className="footerMenu">
            <ul>
                {data.menu.items.map((item, i) => {
                    return (
                        <li key={i}>
                            <Link to={item.url}>{item.title}</Link>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

const FooterContent = props => {
    return (
        <FooterWrapper>
            <FooterLogos />

            <BusinessInfo>
                <div>
                    <h2>{props.site.name}</h2>
                    <span>{props.site.address}</span>
                    <br />
                    <span>{props.site.zipcode} </span>
                    <span>{props.site.city}</span>
                    <br />
                    <span>Tel: </span>
                    <span>{props.site.phone}</span>
                    <br />
                    <span>Org.nr: </span>
                    <span>{props.site.fax}</span>
                </div>
                <FooterMenu />
                <StromStadWrapper>
                    <img
                        src={stromstad}
                        alt="Strömstad - Havet i centrum och livet i fokus"
                    />
                </StromStadWrapper>
            </BusinessInfo>
        </FooterWrapper>
    )
}

class Footer extends Component {
    render() {
        return (
            <Query query={query}>
                {({ loading, err, data }) => {
                    if (loading) return <div>loading</div>
                    return <FooterContent {...data} />
                }}
            </Query>
        )
    }
}
const query = gql`
    {
        site {
            name
            address
            city
            phone
            email
            fax
            zipcode
        }
    }
`

const menuQuery = gql`
    {
        menu(position: "footer") {
            items {
                title
                url
                children {
                    title
                    url
                }
            }
        }
    }
`
export default Footer
