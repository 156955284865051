import React, { Component, Fragment } from "react"
import { Helmet } from "react-helmet"
import gql from "graphql-tag"
import styled from "@emotion/styled"
import { Query, graphql } from "react-apollo"
import { LoadingPage } from "../UI"
import { BumperHeader } from "../UI/Placeholder"
import { PageSection } from "../Page/Page"
import { MaxWidth } from "../UI/Containers"

const PostMaxWidth = styled(MaxWidth)`
    width: 900px;
    max-width: 100%;
    img {
        max-width: 100%;
        margin: 1rem 0;
        height: auto !important;
    }
`

const PageContent = props => {
    const post = props.post
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{post.title}</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
            <BumperHeader />
            <PageSection>
                <PostMaxWidth>
                    <h1>{post.title}</h1>
                    {post.image.url && (
                        <img src={post.image.url} alt={post.title} />
                    )}
                </PostMaxWidth>
                <PostMaxWidth
                    dangerouslySetInnerHTML={{
                        __html: post.content
                    }}
                />
            </PageSection>
        </Fragment>
    )
}

class Page extends Component {
    render() {
        const slug = this.props.match.params.slug
        return (
            <Query query={query} variables={{ slug }}>
                {({ loading, err, data }) => {
                    if (loading) return <LoadingPage />
                    return <PageContent {...data} />
                }}
            </Query>
        )
    }
}
const query = gql`
    query Post($slug: String) {
        post(slug: $slug) {
            title
            content
            time {
                created
                timestamp
            }
            image(version: "large") {
                url
            }
        }
    }
`
export default graphql(query)(Page)
