import React, { useState, useRef } from "react"

export default ({ src, title = "iframe" }) => {
    const [iframeHeight, setHeight] = useState("0px")
    const iframeElm = useRef(null)
    return (
        <iframe
            ref={iframeElm}
            title={title}
            src={src}
            style={{ height: iframeHeight, overflow: "visible" }}
            onLoad={() => {
                setHeight("600px")
            }}
        />
    )
}
