import { withRouter } from "react-router-dom"
import React, { Component } from "react"
import styled from "@emotion/styled"
import { Below } from "../UI/Helpers"

const SearchWrapper = styled("li")`
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    background: ${props => props.theme.colors.primary};
    list-style: none;
    input {
        padding: 1.5rem;
        font-size: 1.5rem;
        width: 100%;
        border: none;
        &,
        &:focus,
        &:active {
            outline: none;
        }
    }
    ${props => props.theme.bp.below.lg} {
        top: auto;
        bottom: 0;
        input {
            background: ${props => props.theme.colors.primaryDark};
            color: #fff;
            &::placeholder {
                color: white;
                opacity: 0.5;
            }
        }
    }
`

const SearchButton = styled("i")`
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 1.5rem;
    font-size: 1.5rem;
    color: white;
`

class Search extends Component {
    state = {
        searchterm: "",
        placeholder: "Här kan du söka..."
    }

    componentDidMount() {
        this.nameInput.focus()
    }

    onKeyPressed = e => {
        const key = e.keyCode
        if (key === 13) {
            this.handleSearch()
        }
    }

    onClick = e => {
        this.handleSearch()
    }

    handleSearch = () => {
        if (this.state.searchterm.trim() !== "") {
            this.props.toggleSearch()
            this.props.toggleMenu()
            this.props.history.push({
                pathname: `/sokresultat/${this.state.searchterm}`
            })
        } else {
            this.setState({
                placeholder: "Du måste skriva något..."
            })
        }
    }

    updateSearch = e => {
        this.setState({ searchterm: e.target.value })
    }

    render() {
        return (
            <SearchWrapper>
                <input
                    ref={input => {
                        this.nameInput = input
                    }}
                    onChange={this.updateSearch}
                    onKeyDown={this.onKeyPressed}
                    placeholder={this.state.placeholder}
                />
                <Below size="lg">
                    <SearchButton
                        onClick={this.onClick}
                        className="fas fa-search"
                    />
                </Below>
            </SearchWrapper>
        )
    }
}

export default withRouter(Search)
