import React, { Component, Fragment } from "react";
import gql from "graphql-tag";
import { Query, graphql } from "react-apollo";
import { LoadingPage } from "../UI";
import Button from "../UI/Button";
import { MaxWidth, Flex } from "../UI/Containers";
import DateDisplay from "../UI/Date";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { BumperHeader } from "../UI/Placeholder";

const NewsMaxWidth = styled(MaxWidth)`
  h1,
  h2 {
    font-family: ${(props) => props.theme.fonts.body};
    text-transform: Uppercase;
    text-align: center;
    margin-bottom: 2em;
    font-size: 21px;
    letter-spacing: 3px;
  }
  ${(props) => props.theme.bp.below.sm} {
    h2 {
      padding: 0 1rem;
    }
  }
`;

const NewsDate = styled(DateDisplay)`
  text-transform: uppercase;
  margin-bottom: 1em;
`;

const NewsFlex = styled(Flex)`
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;

  ${(props) => props.theme.bp.below.md} {
    flex-wrap: wrap;
  }
`;

const News = styled("section")`
  display: flex;
  width: 100%;
  padding: 1rem 0;
  background: #f7f7f7;
`;

const NewsButton = styled(Button)`
  color: ${(props) => props.theme.colors.primary};
  border-color: ${(props) => props.theme.colors.primary};
  margin-top: 10px;
  .has-image & {
    background: #fff;
    color: ${(props) => props.theme.colors.primary}!important;
    border-color: #fff;
  }
`;

const Post = styled("article")`
  width: 33.33%;
  padding: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  position: relative;
  ${(props) => props.theme.bp.below.md} {
    width: 50%;
  }
  ${(props) => props.theme.bp.below.sm} {
    width: 100%;
  }

  date {
    text-transform: uppercase;
    margin-bottom: 1em;
    font-size: 13px;
    letter-spacing: 3px;
  }
  strong {
    font-weight: 300;
  }
  * {
    z-index: 2;
  }
  &.has-image {
    color: #fff;
    a,
    h3,
    h3 a {
      color: #fff;
    }
    &:before,
    &:after {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      content: "";
      z-index: 1;
    }
    &:before {
      background-image: url(${(props) => props.background});
      background-size: cover;
      background-position: center;
      filter: saturate(0);
    }
    &:after {
      background: ${(props) =>
        props.theme.transColor(props.theme.colors.primary, 0.75)};
    }
  }
  h3 {
    font-size: 40px;
    margin-bottom: 0.5em;
  }
  div {
    line-height: 1.5em;
  }
`;

export const SinglePost = ({ post }) => {
  let content =
    post.content.length > 150
      ? post.content.substring(0, 150) + "..."
      : post.content;

  return (
    <Post
      className={post.image.url && "has-image"}
      background={post.image.url && post.image.url}
      key={post.slug}
    >
      <NewsDate timestamp={post.time.timestamp} />
      <h3>
        <Link to={"/nyheter/" + post.slug}>{post.title}</Link>
      </h3>
      <div
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
      <NewsButton to={"/nyheter/" + post.slug}>Läs mer</NewsButton>
    </Post>
  );
};

const NewsListContent = (props) => {
  const posts = props.allPosts.edges;
  return (
    <News>
      <NewsMaxWidth>
        {props.first ? <h2>Nyheter</h2> : <h1>Nyheter</h1>}
        <NewsFlex>
          {posts.map((p, i) => {
            let post = p.node;
            return <SinglePost key={i} post={post} />;
          })}
        </NewsFlex>
      </NewsMaxWidth>
    </News>
  );
};

class NewsList extends Component {
  render() {
    const { first } = this.props;
    return (
      <Fragment>
        <BumperHeader />
        <Query query={query} variables={{ first }}>
          {({ loading, err, data }) => {
            if (loading) {
              return <LoadingPage />;
            }
            return <NewsListContent first={first} {...data} />;
          }}
        </Query>
      </Fragment>
    );
  }
}
const query = gql`
  query LatestPosts($first: Int) {
    allPosts(first: $first) {
      edges {
        node {
          title
          slug
          content
          image {
            url
          }
          time {
            timestamp
          }
        }
      }
    }
  }
`;
export default graphql(query)(NewsList);
