import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import gql from "graphql-tag";
import styled from "@emotion/styled";
import { Query, graphql } from "react-apollo";
import { LoadingPage } from "../UI";
import { FlexMaxWidth } from "../UI/Containers";
import PageHeader from "../Page/PageHeader";
import { BusinessLink } from "./BusinessList";

const PageSection = styled("section")`
  line-height: 1.5em;
  &:nth-child(even) {
    background: ${(props) => props.theme.colors.accent};
  }
  padding: ${(props) => props.theme.spacing.default};
  ${(props) => props.theme.bp.below.sm} {
    padding: ${(props) => props.theme.spacing.small};
  }
  > div {
    padding: 0px 150px;
    ${(props) => props.theme.bp.below.md} {
      padding: 0px 50px;
    }
    ${(props) => props.theme.bp.below.sm} {
      padding: 0px;
    }
  }
  .image-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      max-width: 90%;
      width: auto;
      height: auto;
      margin-top: 1rem;
    }
  }
`;

const Sidebar = styled("aside")`
  max-width: 16rem;
  width: 100%;
  margin-left: 2rem;
  flex-grow: 1;
  ${(props) => props.theme.bp.below.sm} {
    max-width: 100%;
    margin-left: 0;
  }
  h2 {
    margin-bottom: 0.5rem;
  }
  a,
  span {
    display: block;
  }
  img {
    margin: 1.5rem 0;
    max-width: 100%;
    height: auto;
    max-height: 120px;
    max-width: 120px;
  }
`;

const ContactPerson = (props) => (
  <Fragment>
    <h2>{props.name}</h2>

    {props.email && <a href={"mailto:" + props.email}>{props.email}</a>}
    {props.phone && <span>Telefon: {props.phone}</span>}
    {props.mobile && <span>Mobil: {props.mobile}</span>}
  </Fragment>
);

const SideCol = ({ property }) => (
  <Sidebar>
    {property.contact && <ContactPerson {...property.contact} />}
  </Sidebar>
);

class BodyClassSetter extends Component {
  componentWillMount() {
    this.props.pageData.category
      ? (document.getElementsByTagName(
          "body"
        )[0].className = this.props.pageData.category.toLowerCase())
      : (document.getElementsByTagName("body")[0].className = "default");
  }
  componentWillUnmount() {
    document.getElementsByTagName("body")[0].className = "default";
  }

  render() {
    return null;
  }
}

const PageContent = (props) => {
  console.log(props);
  const property = props.rentable;
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{property.property.title}</title>
      </Helmet>
      {property.property.image.url ? (
        <PageHeader
          pageData={{
            ...property.property,
            excerpt: `${property.type} ${
              property.space > 0 ? property.space + "m²" : ""
            }`,
          }}
        />
      ) : null}
      <React.Fragment>
        <PageSection>
          <FlexMaxWidth>
            {console.log(property)}
            <div
              dangerouslySetInnerHTML={{
                __html: property.content,
              }}
            />

            <SideCol property={property} />
          </FlexMaxWidth>
          <FlexMaxWidth>
            <div className="image-container">
              {property.images.length > 0 &&
                property.images.map(({ image }) => {
                  console.log(image);
                  return (
                    <img
                      src={image.url}
                      width={image.width}
                      height={image.height}
                    />
                  );
                })}
            </div>
          </FlexMaxWidth>
        </PageSection>
        <BodyClassSetter pageData={property} />
      </React.Fragment>
    </div>
  );
};

class BusinessPage extends Component {
  render() {
    const slug = this.props.match.params.slug;
    return (
      <Query query={query} variables={{ slug }}>
        {({ loading, err, data }) => {
          if (loading) return <LoadingPage />;
          return <PageContent {...data} />;
        }}
      </Query>
    );
  }
}
const query = gql`
  query Property($slug: String) {
    rentable(slug: $slug) {
      space
      content
      type
      contact {
        name
        email
        phone
        mobile
      }
      images {
        image {
          url
          width
          height
        }
      }
      property {
        title
        content
        excerpt
        website
        category
        image {
          url
          width
          height
        }
        logo {
          url
        }
      }
    }
  }
`;
export default graphql(query)(BusinessPage);
