import React, { Component } from "react"
import { Link } from "react-router-dom"
import styled from "@emotion/styled"
import { Menu } from "../Menu"
import { ReactComponent as Logo } from "../img/logo-old.svg"

const HeaderWrapper = styled("header")`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 5;
    ${props => props.theme.bp.below.md} {
    }
    .logoWrapper {
        height: 140px;
        padding: 30px;
        background: transparent;
        svg {
            height: 100%;
            width: auto;
            path {
                fill: white;
            }
        }
        ${props => props.theme.bp.below.sm} {
            padding: 1.5rem;
            height: 120px;
        }
    }
`

const HeaderContent = props => {
    return (
        <HeaderWrapper>
            <div className="logoWrapper">
                <Link to="/">
                    <Logo />
                </Link>
            </div>
            <Menu {...props} />
        </HeaderWrapper>
    )
}

class Header extends Component {
    state = {
        open: false,
        search: false
    }

    toggleMenu = () => {
        this.setState({ open: !this.state.open })
    }

    toggleSearch = () => {
        this.setState({ search: !this.state.search })
    }
    render() {
        return (
            <HeaderContent
                {...this.state}
                toggleMenu={this.toggleMenu}
                toggleSearch={this.toggleSearch}
            />
        )
    }
}

export default Header
