import React from "react";
import gql from "graphql-tag";
import { Query, graphql } from "react-apollo";
import { LoadingPage } from "../UI";
import Button from "../UI/Button";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";

const Businesses = styled("div")`
  display: flex;
  flex-wrap: wrap;
  background: #fff;
`;

const CityGroup = styled("section")`
  h2 {
    text-align: center;
    padding: 2rem 0;
    font-size: 2rem;
    text-decoration: underline;
  }
`;

const BusinessButton = styled(Button)`
  color: #fff;
  border-color: #fff;
  right: ${(props) => props.theme.spacing.default};
  bottom: ${(props) => props.theme.spacing.default};
  position: absolute !important;
  margin: 0;
  font-size: 12px;
  ${(props) => props.theme.bp.below.sm} {
    font-size: 10px;
    padding: 12px 22px;
  }
  ${(props) => props.theme.bp.below.md} {
    right: ${(props) => props.theme.spacing.small};
    bottom: ${(props) => props.theme.spacing.small};
  }
`;

const Post = styled("article")`
  width: 50vw;
  min-height: 30vw;
  padding: ${(props) => props.theme.spacing.default};
  text-align: left;
  color: #fff;
  position: relative;
  ${(props) => props.theme.bp.above.xl} {
    width: 33.3333vw;
    padding-bottom: 10rem;
  }
  ${(props) => props.theme.bp.below.md} {
    width: 100vw;
    padding: ${(props) => props.theme.spacing.small};
    padding-bottom: 10rem;
  }

  &:after,
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    content: "";
    z-index: 1;
    background-image: url(${(props) => props.background});
    background-size: cover;
    background-position: center;
  }
  &:after {
    z-index: 3;
  }
  &:before {
    filter: saturate(33%);
  }
  &:after {
    background: ${(props) =>
      props.theme.transColor(props.theme.colors.primary, 0.75)};
  }

  span {
    background: ${(props) => props.theme.colors.primary};
    padding: 10px 15px;
    border-radius: 50px;
    font-size: 10px;
    z-index: 4;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 3px;
    &.Fastigheter {
      background: ${(props) => props.theme.colors.properties};
    }
    &.Handel,
    &.Köpcenter {
      background: ${(props) => props.theme.colors.retail};
    }
    &.Investeringar {
      background: ${(props) => props.theme.colors.investments};
    }
  }
  p {
    font-size: 17px;
    line-height: 1.5em;
  }
  h3 {
    z-index: 4;
    position: relative;
    margin: 1em 0 0.2em;
    font-size: 40px;
    a {
      color: #fff;
    }
  }
  a {
    z-index: 4;
    position: relative;
    color: #fff;
  }

  div {
    position: relative;
    z-index: 4;
    line-height: 1.5em;
  }
`;

export const BusinessLink = styled("a")`
  position: ${(props) => (props.static ? "static" : "absolute")}!important;
  left: ${(props) => props.theme.spacing.default};
  bottom: ${(props) => props.theme.spacing.default};
  text-transform: uppercase;
  padding-bottom: 14px;
  border-bottom: 1px solid ${(props) => (props.inline ? "#000" : "#fff")};
  letter-spacing: 3px;
  font-size: 12px;
  ${(props) => props.theme.bp.below.md} {
    left: ${(props) => props.theme.spacing.small};
    bottom: ${(props) => props.theme.spacing.small};
  }
`;

export const SingleBusiness = ({ post, isRentable = false }) => {
  let content = post.content
    ? post.content.length > 350
      ? post.content
          .split(" ")
          .slice(0, 30)
          .join(" ") + "..."
      : post.content
    : null;
  
let firstImage = null
  if('image' in post) {
    firstImage = post.image.url
  }
  if('property' in post && 'image' in post.property) {
    firstImage = post.property.image.url
  }
  if('property' in post && 'firstImage' in post.property) {
    firstImage = post.property.firstImage.url
  }
  if('firstImage' in post) {
    firstImage = post.firstImage.url
  }

  console.log(post,firstImage)
  if (isRentable) {
    const city = post.city ? post.city : post.property.city;
    return (
      <Post background={firstImage} className={post.category}>
        <span className={city}>{city}</span>
        <h3>
          <Link to={"/lediga-objekt/" + post.slug}>
            {post.property.title} - {post.type}
          </Link>
        </h3>
        <div dangerouslySetInnerHTML={{ __html: content }} />

        <BusinessButton to={"/lediga-objekt/" + post.slug}>
          Läs mer
        </BusinessButton>
      </Post>
    );
  }

  return (
    <Post background={post.image.url} className={post.category}>
      <span className={post.city}>{post.city}</span>
      <h3>
        <Link to={"/fastighet/" + post.slug}>{post.title}</Link>
      </h3>
      <div dangerouslySetInnerHTML={{ __html: content }} />
      {post.website !== null && (
        <BusinessLink
          rel="noopener noreferrer"
          target="_blank"
          href={post.website}
        >
          {post.website
            .replace("http://", "")
            .replace("https://", "")
            .replace("www.", "")}
        </BusinessLink>
      )}

      <BusinessButton to={"/fastighet/" + post.slug}>Läs mer</BusinessButton>
    </Post>
  );
};

const BusinessListContent = ({
  allProperties,
  allRentable,
  startpageProperties,
  filters,
  first,
  template
}) => {
  let posts = [];
  
  if (allProperties) {
    posts = groupBy(allProperties.edges, (prop) => prop.node.city);
  } else if (allRentable) {
    posts = groupBy(allRentable.edges, (prop) => prop.node.property.city);
  } else {
    posts = groupBy(startpageProperties.edges, (prop) => prop.node.city);
  }


  return (
    <>
      {Array.from(posts).map(([key, value]) => {
        if (filters && filters.length > 0 && filters.indexOf(key) < 0) {
          return null;
        }
        return (
          <CityGroup>
            {!first && <h2>{key}</h2>}
            <Businesses>
              {value.map((p, i) => {
                return (
                  <SingleBusiness
                    isRentable={typeof allRentable !== "undefined"}
                    post={p.node}
                    key={i}
                  />
                );
              })}
            </Businesses>
          </CityGroup>
        );
      })}
    </>
  );
};

const BusinessList = (props) => {
  const { first, filters, template } = props;
  let active_query = query;
  if (first) {
    active_query = start_query;
  }
  if (template === "FREE") {
    active_query = free_query;
  }
  if (template === "SALE") {
    active_query = sale_query;
  }
  console.log(first);
  return (
    <Query query={active_query} variables={{ first }}>
      {({ loading, err, data }) => {
        if (loading) {
          return <LoadingPage />;
        }
        return (
          <BusinessListContent
            filters={filters}
            {...data}
            first={first}
            template={template}
          />
        );
      }}
    </Query>
  );
};

const query = gql`
  query Properties($first: Int) {
    allProperties(first: $first) {
      edges {
        node {
          category
          title
          slug
          website
          city
          forSale
          firstImage {
            url
          }
          image(version: "business_box") {
            url
          }
        }
      }
    }
  }
`;

const free_query = gql`
  query freeQuery {
    allRentable {
      edges {
        node {
          slug
          content
          contact {
            name
            email
            phone
          }
          firstImage {
            url
          }
          type
          space
          property {
            title
            city
            address
            zipCode
            firstImage {
              url
            }
            image {
              url
              width
              height
            }
            year
            elevator
          }
        }
      }
    }
  }
`;

const sale_query = gql`
  query saleQuery {
    allProperties(forSale: true) {
      edges {
        node {
          category
          title
          slug
          website
          city
          firstImage {
            url
          }
          image(version: "business_box") {
            url
          }
        }
      }
    }
  }
`;

const start_query = gql`
  query startPageProps($first: Int) {
    startpageProperties(first: $first) {
      edges {
        node {
          category
          title
          slug
          website
          city
          image(version: "business_box") {
            url
          }
        }
      }
    }
  }
`;

export function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export default graphql(query)(BusinessList);
