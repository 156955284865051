import { NavLink } from "react-router-dom";
import React, { Component, useState } from "react";
import styled from "@emotion/styled";
import gql from "graphql-tag";
import { Query, graphql } from "react-apollo";
import { Below, Above } from "../UI/Helpers";
import Search from "./Search";

const MenuButton = styled("li")`
  list-style: none;
  display: inline-flex;
  font-size: 0;
  position: relative;
  flex-grow: 1;
  ${(props) => props.theme.bp.below.lg} {
    width: 100%;
    display:flex;
    flex-direction:column;
  }
  a,
  span {
    font-size: 14px;
    white-space: nowrap;
    display: inline-block;
    padding: 2rem 0.5rem;
    text-align: center;
    text-decoration: none;
    color: white;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    width: 100%;
    cursor: pointer;
    ${(props) => props.theme.bp.below.lg} {
      padding: 0.5rem 0;
      font-size: 2.2rem;
      line-height: 2.2rem;
      max-width: calc(100% - 3rem);
      display: block;
      margin: 0 1.5rem;
      text-align: left;
      text-transform: none;
    }
    &:hover,
    &.open {
      background: white;
      color: ${(props) => props.theme.colors.primary};
      .handel &,
      .köpcenter & {
        color: ${(props) =>
          props.theme.transColor(props.theme.colors.retail, 1)};
      }
      .fastigheter & {
        color: ${(props) =>
          props.theme.transColor(props.theme.colors.properties, 1)};
      }
      .investeringar & {
        color: ${(props) =>
          props.theme.transColor(props.theme.colors.investments, 1)};
      }
      ${(props) => props.theme.bp.below.lg} {
        color: white !important;
        background: transparent;
      }
    }
    i {
      margin-left: 0.5rem;
      font-size: 0.6em;
    }
    &.open i {
      transform: rotate(180deg);
    }
    &:after {
      position: absolute;
      content: "";
      bottom: 0;
      width: 100%;
      left: 0;
      border-bottom: 1px solid #fff;
      display: none;
    }
    &.active {
      &:after {
        display: block;
      }
    }
  }
  ul.subMenu {
    position: absolute;
    padding: 0;
    background: white;
    z-index: 2;
    top: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    ${(props) => props.theme.bp.below.lg} {
      background: transparent;
      position: static;
    }
    a {
      color: ${(props) => props.theme.colors.primary};
      padding: 2rem;
      text-align: left;
      max-width: 9999px;
      white-space: nowrap;
      ${(props) => props.theme.bp.below.lg} {
        padding: 0.5rem 0;
        background: none;
        font-weight: 200;
        color: white !important;
      }
    }
  }
`;

const MenuWrapper = styled("ul")`
  position: relative;
  padding: 0;
  margin: 0;
  background: ${(props) => props.theme.colors.primary};
  width: 50vw;
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  .handel &,
  .köpcenter & {
    background: ${(props) => props.theme.colors.retail};
  }
  .fastigheter & {
    background: ${(props) => props.theme.colors.properties};
  }
  .investeringar & {
    background: ${(props) => props.theme.colors.investments};
  }
  ${(props) => props.theme.bp.below.lg} {
    background: ${(props) => props.theme.colors.primary}!important;
    flex-wrap: wrap;
    padding: 0 0.5rem;
    transform: translateX(${(props) => (props.open ? "0" : "100%")});
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    align-content: center;
    justify-items: flex-start;
    z-index: 9;
  }
  transition: 0.2s;
`;

const MenuToggle = styled("button")`
  position: absolute;
  top: 0;
  font-size: 1.7rem;
  width: 3.5rem;
  height: 3.5rem;
  padding: 0.5rem;
  color: white;
  right: ${(props) => (props.open ? "0" : "100%")};
  border: none;
  outline: none;
  background: ${(props) => props.theme.colors.primary};
`;

const SearchToggle = styled(MenuButton)`
  width: 87px;
  max-width: 87px;
  text-align: center;

  span {
    i {
      margin-left: 0;
      font-size: 1.4rem;
    }
  }
  &.isActive {
    background: white;
    span {
      color: ${(props) => props.theme.colors.primary};
      .handel &,
      .köpcenter & {
        color: ${(props) =>
          props.theme.transColor(props.theme.colors.retail, 0.7)};
      }
      .fastigheter & {
        color: ${(props) =>
          props.theme.transColor(props.theme.colors.properties, 0.7)};
      }
      .investeringar & {
        color: ${(props) =>
          props.theme.transColor(props.theme.colors.investments, 0.7)};
      }
    }
  }
`;

const MenuButtonWrapper = ({ submenu, setSubmenu, ...props }) => {
  const className = submenu === props.title ? "open" : "closed";
  return (
    <MenuButton>
      <NavLink
        exact
        className={className}
        key={props.url}
        to={props.url}
        onClick={(e) => {
          if (props.children.length > 0) {
            e.preventDefault();
            if (submenu === props.title) {
              setSubmenu(null);
            } else {
              setSubmenu(props.title);
            }
          } else {
            props.toggleMenu();
          }
        }}
      >
        {props.title}
        {props.children.length > 0 && <i className="fas fa-chevron-down" />}
      </NavLink>
      {submenu === props.title && (
        <SubMenu
          toggleMenu={props.toggleMenu}
          setOpen={setSubmenu}
          children={props.children}
        />
      )}
    </MenuButton>
  );
};

const MenuContent = (props) => {
  const [submenu, setSubmenu] = useState(null);
  return (
    <MenuWrapper {...props}>
      <Below size="lg">
        <MenuToggle open={props.open} onClick={props.toggleMenu}>
          {props.open ? (
            <i className="fas fa-times" />
          ) : (
            <i className="fas fa-bars" />
          )}
        </MenuToggle>
      </Below>
      {props.menu.items.map((m, i) => (
        <MenuButtonWrapper
          submenu={submenu}
          setSubmenu={setSubmenu}
          toggleMenu={props.toggleMenu}
          {...m}
          key={i}
        />
      ))}
      <Above size="lg">
        <SearchToggle
          className={props.search && "isActive"}
          onClick={props.toggleSearch}
        >
          <span>
            <i className="fas fa-search" />
          </span>
        </SearchToggle>
        {props.search && (
          <Search
            toggleSearch={props.toggleSearch}
            toggleMenu={props.toggleMenu}
          />
        )}
      </Above>

      <Below size="lg">
        <Search
          toggleSearch={props.toggleSearch}
          toggleMenu={props.toggleMenu}
        />
      </Below>
    </MenuWrapper>
  );
};

const SubMenu = (props) => {
  return (
    <ul className="subMenu">
      {props.children.map((m, i) => (
        <MenuButton key={i} onClick={() => props.setOpen(false)}>
          <NavLink
            onClick={(e) => {
              props.toggleMenu();
            }}
            exact
            key={m.slug + "-" + i}
            to={m.url}
          >
            {m.title}
          </NavLink>
        </MenuButton>
      ))}
    </ul>
  );
};

const MenuLoading = () => (
  <MenuWrapper>
    <MenuButton>
      <span>Laddar meny...</span>
    </MenuButton>
  </MenuWrapper>
);

class Menu extends Component {
  render() {
    return (
      <Query query={query}>
        {({ loading, err, data }) => {
          if (loading) return <MenuLoading />;
          return <MenuContent {...data} {...this.props} />;
        }}
      </Query>
    );
  }
}
const query = gql`
  {
    menu(position: "header") {
      items {
        title
        url
        children {
          title
          url
        }
      }
    }
  }
`;
export default graphql(query)(Menu);
